export const usePhoneMasks = () => {
  const { t } = useI18n();

  const getMasks = () => ({
    RUS: { mask: '(###)###-##-##', code: '+7', name: t('components.common.phone.Russia') },
    USA: { mask: '(###)###-####', code: '+1', name: t('components.common.phone.USA') },
    CAN: { mask: '(###)###-####', code: '+1', name: t('components.common.phone.Canada') },
    BLR: { mask: '(##)###-##-##', code: '+375', name: t('components.common.phone.Belarus') },
    KAZ: { mask: '(###)###-##-##', code: '+7', name: t('components.common.phone.Kazakhstan') },
    UZB: { mask: '(##)###-####', code: '+998', name: t('components.common.phone.Uzbekistan') },
    TUR: { mask: '(###)###-#####', code: '+90', name: t('components.common.phone.Türkiye') },
    THA: { mask: '(##)###-####', code: '+66', name: t('components.common.phone.Thailand') },
    TJK: { mask: '(##)###-####', code: '+992', name: t('components.common.phone.Tajikistan') },
    SVK: { mask: '(###) ###-###', code: '+421', name: t('components.common.phone.Slovakia') },
    SVN: { mask: '(##)###-###', code: '+386', name: t('components.common.phone.Slovenia') },
    SGP: { mask: '(####)####', code: '+65', name: t('components.common.phone.Singapore') },
    SAU: { mask: '(#)####-####', code: '+966', name: t('components.common.phone.SaudiArabia') },
    ROU: { mask: '(##)###-####', code: '+40', name: t('components.common.phone.Romania') },
    KOR: { mask: '(##)####-####', code: '+82', name: t('components.common.phone.SouthKorea') },
    POL: { mask: '(###) ###-###', code: '+48', name: t('components.common.phone.Poland') },
    ARE: { mask: '(##)###-####', code: '+971', name: t('components.common.phone.UAE') },
    NOR: { mask: '(###) ##-###', code: '+47', name: t('components.common.phone.Norway') },
    NLD: { mask: '(##)###-####', code: '+31', name: t('components.common.phone.Netherlands') },
    MNG: { mask: '(##)##-####', code: '+976', name: t('components.common.phone.Mongolia') },
    LTU: { mask: '(###) ##-###', code: '+370', name: t('components.common.phone.Lithuania') },
    LVA: { mask: '(##)###-###', code: '+371', name: t('components.common.phone.Latvia') },
    CHN: { mask: '(###) ####-####', code: '+86', name: t('components.common.phone.China') },
    KGZ: { mask: '(###) ###-###', code: '+996', name: t('components.common.phone.Kyrgyzstan') },
    ESP: { mask: '(###) ###-###', code: '+34', name: t('components.common.phone.Spain') },
    ITA: { mask: '(###) ####-###', code: '+39', name: t('components.common.phone.Italy') },
    IDN: { mask: '(###) ###-##-###', code: '+62', name: t('components.common.phone.Indonesia') },
    IND: { mask: '(####) ###-###', code: '+91', name: t('components.common.phone.India') },
    ISR: { mask: '(######)####', code: '+972', name: t('components.common.phone.Israel') },
    EGY: { mask: '(###) ###-####', code: '+20', name: t('components.common.phone.Egypt') },
    DNK: { mask: '(##)##-##-##', code: '+45', name: t('components.common.phone.Denmark') },
    GEO: { mask: '(###) ###-###', code: '+995', name: t('components.common.phone.Georgia') },
    GRC: { mask: '(###) ###-####', code: '+30', name: t('components.common.phone.Greece') },
    DEU: { mask: '(###) ###-####', code: '+49', name: t('components.common.phone.Germany') },
    VNM: { mask: '(##)####-###', code: '+84', name: t('components.common.phone.Vietnam') },
    GBR: { mask: '(##)####-#####', code: '+44', name: t('components.common.phone.GreatBritain') },
    BRA: { mask: '(##) #####-####', code: '+55', name: t('components.common.phone.Brazil') },
    BGR: { mask: '(###) ###-###', code: '+359', name: t('components.common.phone.Bulgaria') },
    BEL: { mask: '(###) ###-###', code: '+32', name: t('components.common.phone.Belgium') },
    ARM: { mask: '(##)###-###', code: '+374', name: t('components.common.phone.Armenia') },
    AZE: { mask: '(##)###-##-##', code: '+994', name: t('components.common.phone.Azerbaijan') },
    AUS: { mask: '(##)####-####', code: '+61', name: t('components.common.phone.Australia') },
    ZAF: { mask: '(##)###-####', code: '+27', name: t('components.common.phone.SouthAfrica') },
    EST: { mask: '(####)####', code: '+372', name: t('components.common.phone.Estonia') },
    SWE: { mask: '(##)###-####', code: '+46', name: t('components.common.phone.Sweden') },
    CHE: { mask: '(##)###-####', code: '+41', name: t('components.common.phone.Switzerland') },
    CZE: { mask: '(###) ###-###', code: '+420', name: t('components.common.phone.Czech') },
    MNE: { mask: '(##)###-###', code: '+382', name: t('components.common.phone.Montenegro') },
    HRV: { mask: '(##)###-####', code: '+385', name: t('components.common.phone.Croatia') },
    FRA: { mask: '(###) ###-####', code: '+33', name: t('components.common.phone.France') },
    FIN: { mask: '(###)#######', code: '+358', name: t('components.common.phone.Finland') },
    PHL: { mask: '(###) ###-####', code: '+63', name: t('components.common.phone.Philippines') },
    TKM: { mask: '(#)###-####', code: '+993', name: t('components.common.phone.Turkmenistan') },
    PRT: { mask: '(##)###-####', code: '+351', name: t('components.common.phone.Portugal') },
    NZL: { mask: '(###) ###-###', code: '+64', name: t('components.common.phone.NewZealand') },
    UKR: { mask: '(##)###-##-##', code: '+380', name: t('components.common.phone.Ukraine') },
    JPN: { mask: '(##)####-####', code: '+81', name: t('components.common.phone.Japan') },
  });

  const isValidPhone = (phone: string) => {
    const masks: any = getMasks();

    for (const countryCode in masks) {
      const { mask, code } = masks[countryCode];

      const hashtagPhone = phone.replace(code, '').replace(/\d/g, '#');
      const codePhone = phone.slice(0, code.length)
      const totalLengthPhone = mask.length + code.length
      const lengthPhone = phone.length

      if (hashtagPhone === mask && codePhone === code && totalLengthPhone === lengthPhone) {
        return true;
      }
    }

    return false;
  };

  return {
    masks: getMasks(),
    isValidPhone,
  };

};

